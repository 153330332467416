<template>
<div class='course_main'>
  <div class="nav">
    <ul>
      <li v-for="(item,index) in headernav" :key="index">
        <i class="na-text">{{item.text}}</i>
      </li>
    </ul>
  </div>
  <div class="main" v-html="mainData"></div>
</div>
</template>

<script>
// import { getStore } from '../../tools/storage.js'
import { ref } from 'vue-demi'
import dataOBS from './pageOBS.js'
const headernav = [
  { icon: 'Iphone', text: '首页' },
  { icon: 'Compass', text: '登录' },
  { icon: 'UserFilled', text: '移动端' }
]
export default {
  name: 'LiveCourse',
  data () {
    return {
      mainData: dataOBS,
      show: ref(false)
    }
  },
  components: {},
  methods: {
    init () {
      if (!this.show) {
        this.show = true
        location.reload()
      }
    }
  },
  mounted () {
    // console.log(this.mainData)
    // this.init()
    // const passwords = '111111'
    // console.log(passwords)
    // const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
    // console.log(capitalAccount)
    // const md5password = this.$md5(`${capitalAccount}${passwords}`)
    // console.log(md5password)
  },
  setup (props, ctx) {
    // let show = true
    // const timeer = setTimeout(() => {
    //   show = false
    //   window.location.reload()
    //   // clearInterval(timeer)
    // }, 1000)
    // timeer()
    return {
      headernav
    }
  }
}
</script>
<style lang='scss' scoped>
// @import '../../style/index.scss';
//@import ''; 引入公共css类
.course_main{
  background: #f2efed;
  .nav{
    background: #ffffff;
    height: 30px;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
        .na-text{
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }
    }
  }
  .main{
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    // height: 500px;
  }
}
</style>
